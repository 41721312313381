/* Container and Image */
.banner {
    position: relative;
    width: 100%;
    height: 26rem; /* Equivalent to 'h-96' */
  }
  
  .banner-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.75;
  }
  
  /* Content and Text Container */
  .banner-content {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
  }
  
  .banner-text-container {
    width: 100%;
    max-width: 32rem; /* Equivalent to 'max-w-2xl' */
    text-align: center;
    position: relative;
  }
  
  /* Text Styles */
  .banner-heading {
    font-weight: bold;
    color: white;
    text-shadow: 2px 2px 4px #000, -2px -2px 4px #000;
    margin: 0;
    opacity: 0; /* Hidden by default */
    transition: opacity 0.5s ease;
  }
  
  /* Fade Animations */
  .fade-in {
    opacity: 1;
  }
  
  .fade-out {
    opacity: 0;
  }
  
  /* Dynamic Font Sizes */
  .large-heading {
    font-size: 4rem; /* Larger font size for main text */
  }
  
  .small-heading {
    font-size: 2.5rem; /* Smaller font size for secondary text */
  }
  
  /* Responsive Design */
  @media (max-width: 640px) {
    .large-heading {
      font-size: 2.5rem; /* Adjust for mobile */
    }
  
    .small-heading {
      font-size: 1.5rem; /* Adjust for mobile */
    }
  }
  
  @media (min-width: 641px) and (max-width: 1024px) {
    .large-heading {
      font-size: 3.5rem;
    }
  
    .small-heading {
      font-size: 2rem;
    }
  }
  
  @media (min-width: 1025px) {
    .large-heading {
      font-size: 4rem;
    }
  
    .small-heading {
      font-size: 2.5rem;
    }
  }
  