/* Navbar.css */

/* Navbar */
.navbar {
  background: linear-gradient(to bottom, #f7f7f7, #e0e0e0); /* Light gray gradient */
  border-bottom: 2px solid #5800bc; /* Purple bottom border */
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 50;
  transition: all 0.3s ease;
}

.navbar.shrunk .logo-image {
  height: 3rem; /* Height when navbar is shrunk */
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 1rem;
}

.navbar-left {
  display: flex;
  align-items: center;
}

.logo-link {
  display: flex;
  align-items: center;
  padding: 1rem 0.5rem;
}

.logo-container {
  height: 100%;
  width: 100%;
  border-radius: 1.7rem;
  overflow: hidden;
}

.logo-image {
  width: auto;
  height: 5rem; /* Default height */
  object-fit: cover;
  transition: height 0.3s ease-in-out;
}

.nav-link {
  color: #5800bc; /* Purple text */
  font-weight: 600;
  transition: color 0.3s ease;
  font-size: 1.05rem;
  padding: 1rem 1rem;
  text-decoration: none;
}

.nav-link:hover {
  color: #4c0d99; /* Darker purple on hover */
}

.navbar-item {
  position: relative;
}

.nav-icon {
  margin-left: 0.5rem;
}

.desktop-only {
  display: block;
}

.mobile-only {
  display: none;
}

.hamburger-icon {
  display: none; /* Hidden by default */
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  width: 30px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.hamburger-icon .icon-bar {
  width: 100%;
  height: 3px;
  background-color: #5800bc;
  transition: all 0.3s ease-in-out;
}

.hamburger-icon.open .icon-bar:nth-child(1) {
  transform: translateY(10px) rotate(45deg);
}

.hamburger-icon.open .icon-bar:nth-child(2) {
  opacity: 0;
}

.hamburger-icon.open .icon-bar:nth-child(3) {
  transform: translateY(-6px) rotate(-45deg);
}

/* Mobile Menu Animations */
@keyframes slideIn {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

.mobile-menu {
  position: fixed;
  top: 80px; /* Adjust based on navbar height */
  padding-top: 10px;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 40;
  overflow-y: auto;
  max-height: calc(100vh - 64px);
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

.mobile-menu.slide-in {
  animation-name: slideIn;
}

.mobile-menu.slide-out {
  animation-name: slideOut;
}

.mobile-menu-content {
  display: flex;
  flex-direction: column;
}

.menu-level {
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu-item {
  position: relative;
}

.menu-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #5800bc; /* Purple text */
  font-weight: 600;
  text-decoration: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.menu-link:hover {
  background-color: #f0f0f0;
}

.menu-icon {
  margin-left: 0.5rem;
}

.submenu {
  display: none; /* Hidden by default */
}

/* Show submenus when the menu-item has class 'open' */
.menu-item.open > .submenu {
  display: block;
}

.navbar-dropdown {
  position: absolute;
  top: 100%; /* Position below the button */
  left: 0;
  display: none; /* Hidden by default */
  background-color: white;
  min-width: 10rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
}

.navbar-item:hover > .navbar-dropdown {
  display: block;
}

.submenu {
  position: absolute;
  top: 0;
  left: 100%; /* Position to the right of parent */
  display: none; /* Hidden by default */
  background-color: white;
  min-width: 10rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  margin-left: -1px; /* Slight overlap to prevent gaps */
}

.menu-item:hover > .submenu {
  display: block;
}

/* Responsive Styles */
@media (max-width: 767px) {
  .desktop-only {
    display: none;
  }

  .mobile-only {
    display: block;
  }

  .navbar-left {
    flex-grow: 0;
  }

  /* Mobile Menu Adjustments */
  .navbar-dropdown,
  .submenu {
    position: relative;
    top: 0;
    left: 0;
    box-shadow: none;
    margin-left: 0;
  }

  .submenu {
    padding-left: 1rem;
  }

  /* Hamburger Icon on Mobile */
  .hamburger-icon {
    display: flex;
  }

  /* Adjust logo size on mobile */
  .logo-image {
    height: 4rem; /* Smaller than default but larger than shrunk */
  }

  .navbar.shrunk .logo-image {
    height: 3rem; /* Maintain smaller size when shrunk */
  }
}
