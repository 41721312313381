/* src/styles/HomePage.css */

/* Wrapper that adds a slightly darker background color */
.homepage-wrapper {
    background-color: #f0f0f0; /* Slightly darker color */
    padding: 0;
  }
  
  /* Container that centers the content and controls max width */
  .homepage-container {
    max-width: 95%; /* Adjust as needed */
    margin: 0 auto; /* Center the container */
    background-color: #ffffff; /* White background for content */
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: 6px;
    padding: 2rem;
  }
  
  /* Regions Section */
  .regions-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Title for the regions section */
  .regions-title {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2rem;
    color: #333;
  }

   /* Responsive adjustments */
   @media (max-width: 768px) {
    .regions-title {
    font-size: 1.8rem; /* Smaller font size for mobile screens */
    }   
}

@media (max-width: 480px) {
.regions-title {
  font-size: 1.5rem; /* Even smaller font size for very small screens */
}
}
  
  /* Grid layout for the ProductCards */
  .regions-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 columns */
    gap: 1.5rem;
    width: 100%;
  }
  
  /* Ensure each ProductCard fills its grid cell */
  .regions-grid .product-card {
    width: 100%;
  }
  
  /* Responsive adjustments */
  @media (max-width: 1024px) {
    .regions-grid {
      grid-template-columns: repeat(3, 1fr); /* 3 columns on medium screens */
    }
  }
  
  @media (max-width: 768px) {
    .regions-grid {
      grid-template-columns: repeat(2, 1fr); /* 2 columns on small screens */
    }
  }
  
  @media (max-width: 480px) {
    .regions-grid {
      grid-template-columns: 1fr; /* 1 column on extra small screens */
    }
  }
  