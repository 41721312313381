/* ProductCard.css */

/* Container Styles */
.product-card {
    position: relative;
    width: 100%;
    height: 16rem;
    cursor: pointer;
    border: 1px solid rgb(88, 0, 188);
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
  
  /* Image Styles */
  .product-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.75;
  }
  
  /* Overlay Styles */
  .product-card-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  /* Text Container Styles */
  .product-card-text-container {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 0.5rem;
    border-radius: 0.5rem;
  }
  
  /* Text Styles */
  .product-card-text {
    color: #ffffff;
    font-size: 1.5rem;
    font-weight: bold;
  }
  