/* ProductPage.css */
/* Container and Layout */
.product-page-container {
    width: 100%;
    max-width: 100%;
    margin-left: auto; /* Equivalent to 'mx-auto' */
    margin-right: auto;
    padding-left: 1rem; /* Equivalent to 'px-4' */
    padding-right: 1rem;
    padding-bottom: 1rem;

  }
  
  /* Breadcrumbs */
.breadcrumbs {
    margin: 0 auto; /* Center the breadcrumbs */
    width: 100%; /* Allow full width */
    padding: 0.5rem 1rem; /* Add padding for spacing */
    background-color: #f8f9fa; /* Optional: add a subtle background color */
    border-bottom: 1px solid rgb(88, 0, 188);
    text-align: center; /* Optional: center-align breadcrumb content */
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1); /* Optional: subtle shadow */
    margin-bottom: 1rem;
  }
  
  
  .breadcrumb-link {
    color: rgb(88, 0, 188); /* Equivalent to 'text-blue-600' */
    text-decoration: none;
  }
  
  .breadcrumb-link:hover {
    text-decoration: underline; /* Equivalent to 'hover:underline' */
  }
  
  /* Page Heading */
  .page-heading {
    font-size: 1.875rem; /* Equivalent to 'text-3xl' */
    font-weight: bold; /* Equivalent to 'font-bold' */
    margin-bottom: 1rem; /* Equivalent to 'mb-4' */
    text-align: center;
  }

  /* Divider */
.divider {
    width: 100%;
    height: 1px;
    background-color: #e0e0e0; /* Light gray line */
    margin: 1rem 0;
  }
  
  /* Product Grid */
.product-grid {
    display: grid;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    justify-items: center;
  }
  
  @media (min-width: 768px) {
    .product-grid {
      grid-template-columns: repeat(2, 1fr); /* Change to 2 columns for better spacing */
    }
  }
  
  @media (min-width: 1024px) {
    .product-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  /* Navigation Container */
  .navigation-container {
    margin-bottom: 1.5rem; /* Equivalent to 'mb-6' */
    text-align: center; /* Equivalent to 'text-center' */
  }
  
  .navigation-heading {
    font-size: 1.5rem; /* Equivalent to 'text-2xl' */
    font-weight: bold; /* Equivalent to 'font-bold' */
    margin-bottom: 0.25rem; /* Equivalent to 'mb-1' */
  }
  
  .navigation-links {
    display: inline-flex; /* Equivalent to 'inline-flex' */
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #2563EB;
    border-radius: 6px;
    flex-wrap: wrap; /* Equivalent to 'flex-wrap' */
    justify-content: center; /* Equivalent to 'justify-center' */
  }
  
  .navigation-link {
    padding-left: 1rem; /* Equivalent to 'px-4' */
    padding-right: 1rem;
    padding-top: 0.5rem; /* Equivalent to 'py-2' */
    padding-bottom: 0.5rem;
    margin-bottom: -1px; /* Equivalent to '-mb-px' */
    color: #4B5563; /* Equivalent to 'text-gray-600' */
    text-decoration: none;
  }
  
  .navigation-link:hover {
    color: #2563EB; /* Equivalent to 'hover:text-blue-600' */
  }
  
  .navigation-link.active {
    border-bottom: 2px solid #2563EB; /* Equivalent to 'border-b-2 border-blue-600' */
    color: #2563EB; /* Equivalent to 'text-blue-600' */
    font-weight: 600; /* Equivalent to 'font-semibold' */
  }
  
  /* Product Description */
  .product-description {
    text-align: center; /* Equivalent to 'text-center' */
    margin-top: 3rem; /* Equivalent to 'mt-12' */
  }
  
  .divider {
    width: 6rem; /* Equivalent to 'w-24' */
    height: 0.25rem; /* Equivalent to 'h-1' */
    background-color: #2563EB; /* Equivalent to 'bg-blue-600' */
    margin-left: auto; /* Equivalent to 'mx-auto' */
    margin-right: auto;
    margin-bottom: 1.5rem; /* Equivalent to 'mb-6' */
  }
  
  .product-description-text {
    margin-bottom: 1.5rem; /* Equivalent to 'mb-6' */
  }
  
  /* Viator Widget Container */
  .viator-widget-container {
    max-width: 100%; /* Equivalent to 'max-w-4xl' */
    margin-left: auto; /* Equivalent to 'mx-auto' */
    margin-right: auto;
    margin-top: 3rem; /* Equivalent to 'mt-12' */
  }
  
  /* Product Not Found */
  .product-not-found {
    width: 100%;
    max-width: 1024px; /* Equivalent to 'max-w-screen-lg' */
    margin-left: auto; /* Equivalent to 'mx-auto' */
    margin-right: auto;
    padding-top: 2rem; /* Equivalent to 'py-8' */
    padding-bottom: 2rem;
  }
  
  .content-area {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  }
  /* Additional Styles if needed */
  