/* Main content padding to align with the navbar */
.main-content {
  padding-top: 64px; /* Matches navbar height */
  flex-grow: 1; /* Ensures the main content takes the remaining space */
  display: flex;
  flex-direction: column;
}

/* Optional: Style the app container to take full height */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
